body {
  margin: 0;
  height: 100vh;
  width: 100%;
}

#root {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}